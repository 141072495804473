/* Section principale */
.publications {
    padding: 60px 20px;
    background-color: #f7f9f5;
    border-radius: 10px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: "Arial", sans-serif;
    line-height: 1.8;
    color: #1b5e20;
}

/* Titre */
.publications h2 {
    font-size: 2.5rem;
    color: #1b5e20;
    text-align: center;
    margin-bottom: 40px;
    font-weight: bold;
}

/* Liste des publications */
.publication-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    justify-content: center;
}

/* Carte de publication */
.publication-card {
    background-color: #e8f5e9;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: left;
    padding: 20px;
}

.publication-card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
}

/* Titre de publication */
.publication-card h3 {
    font-size: 1.2rem;
    color: #1b5e20;
    margin-bottom: 10px;
}

/* Description */
.publication-card p {
    font-size: 1rem;
    line-height: 1.5;
    color: #2d3436;
    margin-bottom: 10px;
}

/* Bouton "Read Publication" */
.publication-link {
    display: inline-block;
    padding: 8px 15px;
    background-color: #1b5e20;
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.publication-link:hover {
    background-color: #145a14;
    transform: translateY(-3px);
}

/* Bouton "Voir Plus" */
.show-more-btn {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #1b5e20;
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.show-more-btn:hover {
    background-color: #145a14;
    transform: translateY(-3px);
}

/* Responsive Design */
@media (max-width: 768px) {
    .publications {
        padding: 40px 15px;
    }

    .publications h2 {
        font-size: 2rem;
        margin-bottom: 30px;
    }

    .publication-card {
        padding: 15px;
    }

    .publication-card h3 {
        font-size: 1rem;
    }

    .publication-card p {
        font-size: 0.9rem;
    }

    .publication-link {
        font-size: 0.9rem;
        padding: 6px 10px;
    }
}

@media (max-width: 480px) {
    .publications h2 {
        font-size: 1.8rem;
    }

    .publication-card {
        padding: 10px;
    }

    .publication-card h3 {
        font-size: 0.9rem;
    }

    .publication-card p {
        font-size: 0.8rem;
    }

    .publication-link {
        font-size: 0.8rem;
        padding: 5px 8px;
    }
}