/* src/components/About.css */
.about {
    padding: 50px 20px;
    background-color: #f8f9f5;
    /* Couleur douce */
    border-radius: 10px;
    margin: 20px auto;
}

.about-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.about-photo {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 4px solid #4caf50;
    /* Bordure verte */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    /* Ombre douce */
}

.about-text {
    max-width: 600px;
    text-align: left;
}

.about-text h2 {
    font-size: 2rem;
    color: #2e7d32;
    /* Vert foncé */
    margin-bottom: 10px;
}

.about-text p {
    font-size: 1rem;
    line-height: 1.5;
    color: #2d3436;
    /* Gris foncé */
}