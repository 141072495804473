/* Section principale */
.contact {
    padding: 60px 20px;
    background-color: #f7f9f5;
    /* Blanc cassé */
    border-radius: 10px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: "Arial", sans-serif;
    line-height: 1.8;
    color: #1b5e20;
    /* Vert foncé */
}

/* Titre */
.contact h2 {
    font-size: 2.5rem;
    color: #1b5e20;
    /* Vert foncé */
    text-align: center;
    margin-bottom: 40px;
    font-weight: bold;
}

/* Formulaire */
.contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f9f5;
    /* Fond blanc */
}

/* Champs du formulaire */
.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 12px 15px;
    border: 2px solid #1b5e20;
    /* Vert foncé */
    border-radius: 8px;
    font-size: 1rem;
    color: #2d3436;
    /* Gris foncé */
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
    border-color: #4caf50;
    /* Vert clair */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    outline: none;
}

/* Bouton de soumission */
.contact-form button {
    padding: 12px 20px;
    background-color: #1b5e20;
    /* Vert foncé */
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-transform: uppercase;
}

.contact-form button:hover {
    background-color: #145a14;
    /* Vert encore plus foncé */
    transform: translateY(-3px);
}

/* Message de confirmation ou d'erreur */
.contact-message {
    text-align: center;
    font-size: 1.1rem;
    color: #388e3c;
    /* Vert succès */
    margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .contact-form {
        padding: 15px;
        box-shadow: none;
        /* Simplifie l'interface sur mobile */
    }

    .contact h2 {
        font-size: 2rem;
        /* Réduit la taille du titre sur mobile */
    }

    .contact-form button {
        font-size: 1rem;
        /* Réduit légèrement le texte du bouton */
    }

    .contact-form input,
    .contact-form textarea {
        font-size: 0.9rem;
        /* Réduit la taille des champs sur mobile */
    }
}

@media (max-width: 480px) {
    .contact h2 {
        font-size: 1.8rem;
        /* Réduit encore plus la taille du titre */
    }

    .contact-form {
        gap: 15px;
        /* Réduit l'espacement entre les champs */
    }

    .contact-form button {
        padding: 10px;
        font-size: 0.9rem;
    }
}