/* Section principale */
.projects {
    padding: 60px 20px;
    background-color: #f7f9f5;
    /* Blanc cassé */
    border-radius: 10px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: "Arial", sans-serif;
    line-height: 1.8;
    color: #1b5e20;
    /* Vert foncé */
    text-align: center;
    height: auto;
}

/* Titre de la section */
.projects h2 {
    font-size: 2.5rem;
    color: #1b5e20;
    /* Vert foncé */
    margin-bottom: 40px;
    font-weight: bold;
}

/* Navbar principale */
.projects-navbar {
    background-color: #1b5e20;
    /* Vert foncé */
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.projects-navbar ul {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.projects-navbar li {
    margin: 0 15px;
}

.projects-navbar button {
    background: none;
    border: none;
    color: #ffffff;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    transition: color 0.3s ease, text-decoration 0.3s ease;
}

.projects-navbar button:hover {
    color: #a5d6a7;
    /* Vert clair */
}

.projects-navbar .active {
    text-decoration: underline;
}

/* Sous-navbar */
.projects-sub-navbar {
    background-color: #388e3c;
    /* Vert plus foncé */
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.projects-sub-navbar ul {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.projects-sub-navbar li {
    margin: 0 10px;
}

.projects-sub-navbar button {
    background: none;
    border: none;
    color: #d8f3dc;
    /* Vert pastel */
    font-size: 0.9rem;
    cursor: pointer;
    transition: color 0.3s ease, text-decoration 0.3s ease;
}

.projects-sub-navbar button:hover {
    color: #b7e4c7;
}

.projects-sub-navbar .active {
    text-decoration: underline;
    font-weight: bold;
    color: #ffffff;
}

/* Grille des projets */
.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
    height: auto;
}

/* Carte de projet */
.project-card {
    position: relative;
    background-color: #e8f5e9;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease;
    text-align: left;
    height: auto;
}

.project-card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
    background-color: #4caf50;
    /* Vert */
    color: #ffffff;
    height: auto;
    /* Texte blanc */
}

/* Partie visible par défaut */
.project-visible {
    padding: 20px;
    z-index: 1;
    transition: opacity 0.3s ease;
    height: auto;
}

.project-visible h3,
.project-visible p {
    opacity: 1;
    transition: opacity 0.3s ease;
}

.project-card:hover .project-visible h3,
.project-card:hover .project-visible p {
    opacity: 0;
    height: auto;
    /* Cache le titre et la description au survol */
}

/* Image du projet */
.project-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 10px;
}

/* Lien vers les détails */
.project-link {
    display: inline-block;
    padding: 8px 15px;
    background-color: #00bfa6;
    /* Vert doux */
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    font-size: 0.9rem;
    font-weight: bold;
    margin-top: 10px;
    height: auto;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.project-link:hover {
    background-color: #008c72;
    /* Vert plus foncé */
    color: #ffffff;
    height: auto;
}

/* Partie cachée au survol */
.project-hidden {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #4caf50;
    /* Vert */
    color: #ffffff;
    /* Texte blanc */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Centrer verticalement */
    align-items: center;
    /* Centrer horizontalement */
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    padding: 0;
    /* Supprime les espaces internes inutiles */
    margin: 0;

    /* Supprime les marges inutiles */
}

.project-card:hover .project-hidden {
    opacity: 1;

    /* Affiche les détails au survol */
}

/* Texte caché au survol */
.project-hidden p {
    margin: 5px;
    text-align: center;
    font-size: 0.9rem;
    color: #ffffff;
    height: auto;
    /* Texte blanc */
}

/* Icônes */
.project-hidden p i {
    margin-right: 10px;
    color: #ffffff;
    /* Icônes blanches */
    font-size: 1.2rem;
}

/* Texte spécial pour le groupe */
.project-hidden .group-info {
    margin-top: 10px;
    font-size: 1rem;
    font-weight: bold;
    text-align: left;

}

/* Détail individuel */
.project-detail {
    display: flex;
    align-items: center;
    /* Centre le texte verticalement par rapport aux icônes */
    justify-content: flex-start;
    /* Garde l'alignement à gauche si nécessaire */
    font-size: 0.9rem;
    /* Taille du texte */
    margin: 8px 0px;
    height: auto;
    /* Espacement entre les lignes */
}

/* Icônes */
.project-detail .h-5 {
    width: 30px;
    /* Taille des icônes */
    height: 30px;
    margin-right: 10px;
    flex-shrink: 0;

    /* Espacement entre l'icône et le texte */
}

/* Bouton pour le lien GitHub */
.project-link-button {
    display: flex;
    align-items: center;
    /* Centre l'icône et le texte verticalement */
    justify-content: center;
    /* Centre horizontalement le contenu du bouton */
    padding: 8px 12px;
    background-color: #1b5e20;
    color: #ffffff;
    /* Texte vert */
    text-decoration: none;
    border-radius: 5px;
    font-size: 0.9rem;
    /* Taille du texte */
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 10px;
    margin-bottom: 10px;
    /* Espacement avec les autres détails */
}

.project-link-button:hover {
    background-color: #ffffff;
    margin: 10px;
    /* Vert foncé au survol */
    color: #1b5e20;
    /* Texte blanc */
}

/* Icône dans le bouton */
.project-link-button .h-5 {
    width: 30px;
    /* Taille de l'icône */
    height: 30px;
    margin-right: 8px;
    /* Espace entre l'icône et le texte */
}

/* Conteneur pour les boutons GitHub/Kaggle */
.project-link-container {
    display: flex;
    flex-wrap: wrap;
    /* Permet d'aller à la ligne si nécessaire */
    gap: 10px;
    /* Espace entre les boutons */
    justify-content: space-between;
    /* Répartir l'espace entre les boutons */
}

/* Style pour les boutons des liens GitHub/Kaggle */
.project-link-button-small {
    flex: 1 1 calc(30% - 10px);
    /* Chaque bouton occupe 50% moins l'espace entre les colonnes */
    padding: 6px 5px;
    /* Taille réduite du bouton */
    background-color: #1b5e20;
    /* Vert foncé */
    color: #ffffff;
    /* Blanc */
    text-decoration: none;
    border-radius: 5px;
    font-size: 0.8rem;
    /* Taille réduite du texte */
    font-weight: bold;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: flex;
    /* Pour centrer l'icône et le texte */
    align-items: center;
    justify-content: center;
}

/* Icône plus petite dans le bouton */
.project-link-button-small .h-4 {
    width: 10px;
    height: 10px;
    /* Taille réduite de l'icône */
    margin-right: 5px;
}

/* Effet au survol */
.project-link-button-small:hover {
    background-color: #388e3c;
    /* Vert plus clair */
    transform: translateY(-2px);
}

.show-more-btn {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #1b5e20;
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.show-more-btn:hover {
    background-color: #388e3c;
    transform: translateY(-3px);
}

.project-card.two-columns .project-hidden {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    align-items: flex-start;
    padding: 20px;
}

/* Section principale */
.projects {
    padding: 60px 20px;
    background-color: #f7f9f5;
    border-radius: 10px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: "Arial", sans-serif;
    line-height: 1.8;
    color: #1b5e20;
    text-align: center;
}

/* Titre de la section */
.projects h2 {
    font-size: 2.5rem;
    color: #1b5e20;
    margin-bottom: 40px;
    font-weight: bold;
}

/* Navbar principale */
.projects-navbar {
    background-color: #1b5e20;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.projects-navbar ul {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
}

.projects-navbar li {
    margin: 0 15px;
}

/* Sous-navbar */
.projects-sub-navbar {
    background-color: #388e3c;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.projects-sub-navbar ul {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
}

.projects-sub-navbar li {
    margin: 0 10px;
}

/* Grille des projets */
.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

/* Carte de projet */
.project-card {
    position: relative;
    background-color: #e8f5e9;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: left;
}

.project-card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
    background-color: #4caf50;
    color: #ffffff;
}

/* Partie visible par défaut */
.project-visible {
    padding: 20px;
    z-index: 1;
    transition: opacity 0.3s ease;
}

.project-visible h3 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #1b5e20;
    margin-bottom: 10px;
}

.project-visible p {
    font-size: 0.9rem;
    color: #388e3c;
}

/* Partie cachée au survol */
.project-hidden {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #4caf50;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    padding: 15px;
    box-sizing: border-box;
}

.project-card:hover .project-hidden {
    opacity: 1;
}

.project-hidden p {
    margin: 5px 0;
    text-align: center;
    font-size: 0.9rem;
}

/* Icônes */
.project-hidden p i {
    margin-right: 10px;
    color: #ffffff;
    font-size: 1rem;
}

/* Boutons */
.project-link-button {
    display: flex;
    /* Utiliser Flexbox */
    align-items: center;
    /* Aligner verticalement */
    justify-content: center;
    /* Centrer horizontalement */
    padding: 8px 12px;
    background-color: #1b5e20;
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    font-size: 0.9rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
    gap: 8px;
    /* Espace entre l'icône et le texte */
}


.project-link-button:hover {
    background-color: #388e3c;
}