/* Section principale */
.about {
    padding: 60px 20px;
    background-color: #f7f9f5;
    /* Blanc cassé */
    border-radius: 10px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: "Arial", sans-serif;
    line-height: 1.8;
    color: #1b5e20;
    /* Vert foncé */
}

/* Titre de la section */
.section-title {
    font-size: 2.5rem;
    color: #1b5e20;
    text-align: center;
    margin-bottom: 40px;
    font-weight: bold;
}

/* Contenu principal */
.about-content {
    display: flex;
    gap: 40px;
    align-items: flex-start;
    flex-wrap: wrap;
    /* Permet de passer en colonne pour mobile */
    justify-content: center;
}

.about-content.expanded {
    flex-direction: column;
    align-items: center;
}

/* Photo */
.about-photo-container {
    flex: 1;
    text-align: center;
    max-width: 300px;
    /* Limite la taille de la photo */
    margin: 0 auto;
}

.about-photo {
    width: 220px;
    height: 220px;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid #1b5e20;
    /* Vert foncé */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

/* Boutons sous la photo */
.download-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.download-cv-btn {
    padding: 10px 20px;
    background-color: #1b5e20;
    /* Vert foncé */
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.download-cv-btn:hover {
    background-color: #145a14;
    /* Vert encore plus foncé */
    transform: translateY(-3px);
}

/* Texte */
.about-text {
    flex: 2;
    max-width: 600px;
    /* Limite la largeur du texte */
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.about-text.expanded {
    flex: 1;
}

/* Paragraphes supplémentaires */
.about-paragraphs {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.about-paragraph {
    background-color: #e8f5e9;
    /* Vert clair */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-paragraph:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.about-paragraph p {
    margin: 0;
    font-size: 1rem;
    color: #1b5e20;
    line-height: 1.8;
}

/* Paragraphes supplémentaires */
.about-paragraphs-expanded {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Bouton "Lire Plus" */
.read-more-container {
    margin-top: 20px;
    text-align: center;
}

.read-more-btn {
    padding: 10px 20px;
    background-color: #1b5e20;
    /* Vert foncé */
    color: #ffffff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.read-more-btn:hover {
    background-color: #145a14;
    /* Vert encore plus foncé */
    transform: translateY(-3px);
}

/* Responsiveness */
@media (max-width: 768px) {
    .about-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
        /* Centrer le contenu sur mobile */
    }

    .about-photo {
        width: 180px;
        height: 180px;
    }

    .about-text {
        max-width: 100%;
    }

    .section-title {
        font-size: 2rem;
    }

    .download-cv-btn {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .section-title {
        font-size: 1.8rem;
    }

    .about-photo {
        width: 150px;
        height: 150px;
    }

    .download-cv-btn {
        padding: 8px 15px;
        font-size: 0.8rem;
    }
}