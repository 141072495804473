/* Navbar principale */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #1b5e20;
    color: #f7f9f5;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 10px 10px;
    font-family: "Arial", sans-serif;
}

/* Navbar header (title and menu toggle) */
.navbar-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.navbar-title {
    font-size: 1.5rem;
    font-weight: bold;
}

/* Menu toggle button */
.menu-toggle {
    display: none;
    font-size: 1.5rem;
    background: none;
    border: none;
    color: #f7f9f5;
    cursor: pointer;
}

/* Liste des liens */
.navbar-links {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
}

.navbar-links li {
    display: inline;
}

/* Liens */
.navbar-links a {
    color: #f7f9f5;
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
    padding: 8px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.navbar-links a:hover {
    background-color: #4caf50;
    color: #ffffff;
    transform: translateY(-3px);
}

/* Language switcher */
.language-switcher button {
    background-color: #ffffff;
    color: #1b5e20;
    border: 2px solid #1b5e20;
    padding: 5px 15px;
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.language-switcher button:hover {
    background-color: #1b5e20;
    color: #ffffff;
    transform: translateY(-3px);
}

/* Responsive Styles */
@media (max-width: 768px) {
    .menu-toggle {
        display: block;
    }

    .navbar-links {
        flex-direction: column;
        align-items: center;
        display: none;
        width: 100%;
        gap: 15px;
    }

    .navbar-links.open {
        display: flex;
    }

    .navbar-links a {
        font-size: 1.2rem;
        width: 100%;
        text-align: center;
    }
}