/* Section principale */
.home {
    background-color: #f7f9f5;
    /* Blanc cassé */
    padding: 40px 20px;
    font-family: "Arial", sans-serif;
    text-align: center;
    line-height: 1.8;
}

/* Bannière */
.banner {
    background: linear-gradient(to right, #d0f0d8, #e8f5e9);
    /* Gradient vert clair à vert très clair */
    color: #145a14;
    padding: 40px 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    /* Permet de passer en colonne sur mobile */
}

/* Disposition en 3 colonnes */
.banner-columns {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    gap: 20px;
    width: 100%;
    /* Utilise toute la largeur disponible */
}

/* Icônes */
.icon-left,
.icon-right {
    font-size: 150px;
    /* Ajusté pour être plus lisible */
    padding: 20px;
    color: #145a14;
    transition: transform 0.3s ease, color 0.3s ease;
}

.icon-left:hover,
.icon-right:hover {
    transform: scale(1.1);
    color: #a5d6a7;
    /* Vert clair au survol */
}

/* Texte au centre */
.banner-text {
    text-align: center;
    color: #309b37;
}

/* Texte au centre */
.banner-text h1 {
    text-align: center;
    color: #145a14;
    font-size: 3.5rem;
    font-family: "Dancing Script", cursive;
    /* Police calligraphique */
    font-weight: bold;
    margin: 0;
}

.banner-text h2 {
    font-size: 1.5rem;
    margin: 10px 0;
}

.banner-text h3 {
    font-size: 1.2rem;
    font-style: italic;
    color: #28bb28;
    /* Vert clair pastel */
    margin-top: 10px;
}

/* Boutons */
.home-actions {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    /* Rend les boutons responsifs */
}

.home-actions .btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #1b5e20;
    /* Vert foncé */
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.home-actions .btn:hover {
    background-color: #388e3c;
    /* Vert moyen */
    transform: translateY(-3px);
    color: #ffffff;
}

h4 {
    font-size: 2rem;
    font-weight: bold;
    color: #1b5e20;
    margin-bottom: 20px;
}

p {
    font-size: 1rem;
    color: #388e3c;
    margin-bottom: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .banner-columns {
        grid-template-columns: 1fr;
        /* Une seule colonne sur mobile */
        text-align: center;
    }

    .icon-left,
    .icon-right {
        font-size: 100px;
        /* Réduit la taille des icônes */
        padding: 10px;
    }

    .banner-text h1 {
        font-size: 2.5rem;
        /* Réduit la taille du texte principal */
    }

    .banner-text h2,
    .banner-text h3 {
        font-size: 1rem;
        /* Réduit les tailles des sous-titres */
    }
}

@media (max-width: 480px) {

    .icon-left,
    .icon-right {
        font-size: 80px;
        /* Réduit davantage les icônes */
    }

    .banner-text h1 {
        font-size: 2rem;
        /* Ajuste le titre principal */
    }

    .banner-text h2,
    .banner-text h3 {
        font-size: 0.9rem;
        /* Ajuste les sous-titres */
    }

    .home-actions .btn {
        padding: 8px 15px;
        /* Réduit les boutons */
        font-size: 0.9rem;
    }
}